@import '~stylevariables/colors';
@import '~stylevariables/spacing';
@import '~stylevariables/breakpoints';
@import '~stylevariables/type';

$typeGap: (
  'base': 3rem,
  'tablet': 5rem
);
.Type {
  display: grid;
  @include breaks('grid-gap', $typeGap);
}

.Type-face {
  display: grid;
  align-items: start;
  grid-template-areas:
    "heading"
    "specimin"
    "explainer"
    "credit"
  ;
  grid-gap: 1em;

  @include breakpoint(800px) {
    grid-template-areas:
      "specimin heading"
      "specimin explainer"
      "specimin credit"
    ;
    grid-template-rows: auto auto 1fr;
    grid-template-columns: 2fr 1fr;
    grid-column-gap: 3em;
  }
}


.Type-face-specimin {
  align-self: start;
  word-break: break-all;
  grid-area: specimin;
  background-color: $lightgray;
  p {
    margin-top: .5em;
    margin-bottom: .5em;
  }

  p:first-child {
    margin-top: 0;
  }
  p:last-child {
    margin-bottom: 0;
  }
}
.Type-face-explainer {
  grid-area: explainer;
}
.Type-face-credit {
  grid-area: credit;
}

$typePad: (
  'base': 1rem,
  'tablet': 3rem
);
.Type-face-explainer,
.Type-face-heading {
  //@include breaks('padding-left', $typePad);
}

.Type-face-specimin, {
  @include breaks('padding', $typePad);
}

.Type-styles {
  margin-top: 6em;
}
.Type-style {
  border-top: 1px solid;
  padding-top: 2em;

  &:not(:last-child) {
    padding-bottom: 2em;
  }
  display: grid;
  grid-template-areas:
    "name"
    "ipsum"
    "meta"
  ;
  grid-gap: 2em;

  @include breakpoint(600px) {
    grid-template-columns: 100px 1fr minmax(300px,1fr);
    grid-template-areas:
      "name . ."
      "ipsum ipsum meta"
    ;
    grid-gap: 2em;
  }
  @include breakpoint(1000px) {
    align-items: center;
    grid-gap: 4em;
    grid-template-columns: 100px 1fr 300px;
    grid-template-areas: "name ipsum meta";
  }
}
.Type-style-name {
  grid-area: name;
  opacity: .3;
}
.Type-style-ipsum {
  grid-area: ipsum;
}
.Type-style-meta {
  grid-area: meta;
}

.Type-style-meta-details {
  display: grid;
  grid-template-columns: auto 1fr;
  margin: 0;
  dt {
    grid-column: 1;
    display: flex;
    align-items: center;
    opacity: .3;
    //justify-content: flex-end;
  }
  dd {
    grid-column: 2
  }
}