@import "~stylevariables/spacing";

.NavBar {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 2;
  svg * {
    fill: inherit;
  }
}

.App {
  padding-top: $navHeight;
}
.NavBar-bar {
  align-items: center;
  display: grid;
  height: $navHeight;
  padding: 8px;
  position: relative;
  z-index: 1;
}

.NavBar-menu .TextNav {
  min-height: calc(100vh - #{$navHeight});
}
.TextNav {
  align-items: center;
  display: flex;
  flex-direction: column;
  padding-top: 100px;
}
.TextNav a {
  color: inherit;
  display: block;
  padding: .5em 0;
  margin: .5em 0;
  text-decoration: none;
}

.NavBar-toggle {
  position: fixed;
  top: 12px;
  background-color: transparent;
  border: 0;
  &:focus {
    outline: 0;
  }
}
.NavBar-bar-homelink {
  height: 30px;
}
.NavBar-bar-homelink svg {
  display: block;
  height: 100%;
  margin: 0 auto;
}