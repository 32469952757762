$family-industry: industry, sans-serif;
$family-aktiv: aktiv-grotesk-extended, sans-serif;
$family-domaine: 'domaine', serif;

$labelSizes: (
  'base': 14px,
  'tablet': 18px
);

$sublabelSizes: (
  'base': 12px,
  'tablet': 16px
);

$headingSizes: (
  'base': 24px,
  'tablet': 24px
);

$subheadingSizes: (
  'base': 18px,
  'tablet': 21px
);

$speciminSizes: (
  'base': 21px,
  'tablet': 28px
);

$bodySizes: (
  'base': 16px,
  'tablet': 16px
);

$heroSizes: (
  'base': 36px,
  'tablet': 48px
);