@import "~stylevariables/breakpoints";

.Section {
  margin-top: 50px;
  margin-bottom: 50px;
  padding-bottom: 100px;
}

$sectionInnerMargin: (
  'base': 4rem,
  'tablet': 6rem
);
.Section-inner {
  @include breaks('margin-top', $sectionInnerMargin);
}

$sectionHeaderMargin: (
  'base': 4rem,
  'tablet': 8rem
);
.Section-header {
  max-width: 800px;
  margin-left: auto;
  margin-right: auto;

  @include breaks('margin-top', $sectionHeaderMargin);
}

.Section-heading {
  text-align: center;
}

.Section-explainer {
  margin-top: 1em;
}

.Section .ColorCombo {
  margin-top: 4em;
}