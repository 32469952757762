@import "~stylevariables/spacing";
@import "~stylevariables/breakpoints";
$comboBreak: 700px;
$comboBreak-large: 1040px;

.ColorCombo-heading {
  margin-bottom: 1em;
  text-align: center;
}
.ColorCombo-explainer {
  margin-bottom: 2rem;
}
.ColorCombo-inner {
  align-items: start;
  display: grid;
  grid-gap: 2em;

  @include breakpoint($comboBreak) {
    grid-gap: 2em;
    grid-template-columns: 1fr 210px;
  }
}
.ColorCombo-controls {
  display: grid;
  grid-template-columns: auto 1fr;

  @include breakpoint($comboBreak) {
    grid-template-columns: 1fr;
  }
}
.ColorCombo-controls-control-label {
  margin-bottom: 1em;
}
.ColorCombo-controls-modes {
  display: grid;

  @include breakpoint($comboBreak) {
    grid-template-columns: repeat(2,70px)
  }
}
.ColorCombo-controls-modes-mode {
  width: 70px;
  height: 70px;
  padding: 5px;
}
.ColorCombo-controls-modes-mode-button {
  display: block;
  padding: 0;
  border: 1px solid transparent;
  width: 100%;
  height: 100%;
  margin: 0;
  border-radius: 50%;
  padding: 5px;

  svg {
    display: block;
  }
  &:focus {
    outline: 0;
  }
}
.ColorCombo-controls-modes-mode-button--selected {
  border-color: inherit;
}
.ColorCombo-controls-color {
  display: grid;
  grid-gap: 1em;
  justify-content: start;

  @include breakpoint(375px) {
    grid-template-columns: 1fr 1fr;

    .ColorCombo-controls-color-control {
      max-width: 210px;
    }
  }

  @include breakpoint(500px) {
    grid-gap: 2em;
    grid-template-columns: auto auto;
  }

  @include breakpoint($comboBreak) {
    grid-template-columns: 1fr;
    grid-template-rows: auto auto;
  }
}


.ColorCombo-sandbox {
  align-items: center;
  display: flex;
  align-self: stretch;

  .SandboxSample {
    @include breakpoint($comboBreak) {
      max-height: unset;
    }
  }
}