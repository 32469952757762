.Login {
  align-items: center;
  display: grid;
  grid-template-rows: 50px 1fr 50px;
  justify-content: center;
  height: 100vh;
}

.Login-logo {
  padding: 10px;
  svg {
    display: block;
    height: 30px;
    width: auto;
    margin: 0 auto;
    * {
      fill: inherit;
    }
  }
}

.Login-inner {
  max-width: 300px;
  margin-left: auto;
  margin-right: auto;
  position: relative;
  input {
    border: 0;
    display: block;
    font-size: 1em;
    padding: 10px 20px;
    font-family: inherit;
    width: 100%;
    padding-right: calc(1rem + 15px);
    &:focus {
      outline: 0;
    }
    &::placeholder {
      text-transform: uppercase;
    }
  }
}
.Login-password-label {
  position: absolute;
  transform: translateY(-100%);
}
.Login-password {
  grid-area: password;
}
.Login-error {
  color: red;
  grid-area: error;
  position: absolute;
  top: 100%;
}
.Login-submit {
  background-color: unset;
  border: unset;
  position: absolute;
  right: .5rem;
  top: 50%;
  transform: translateY(-50%);
  width: 1rem;
  z-index: 1;
  padding: 0;

  &[disabled] {
    pointer-events: none;
    opacity: .5;
  }

  svg {
    display: block;
  }
}