@import "~stylevariables/spacing";
@import "~stylevariables/breakpoints";

.SandboxSample {
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.SandboxSample-samples {
  @include breaks('padding', $boxpad);
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  flex-direction: column;
  max-height: 100%;
  overflow-y: auto;

}
.SandboxSample-samples > div + div {
  margin-top: 1rem;
}
.SandboxSample-samples svg {
  display: block;
  max-height: 40vh;

  * {
    fill: inherit;
  }
}

.SandboxSample-choices {
  display: flex;
  background-color: #fff;
}

.SandboxSample-choices-choice {
  margin-bottom: 3px;
  margin-left: 3px;
  cursor: pointer;

  &:first-child {
    margin-left: 0;
  }

  svg {
    display: block;
    width: 100%;
    height: auto;
  }
}

.SandboxSample-actions {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
  padding: 5px 10px;

  a {
    background-color: rgba(255,255,255,.25) !important;
    color: inherit;
    text-decoration: none;
    display: inline-flex;
    justify-content: center;
    padding: 3px 10px;

    > div {
      align-items: center;
      display: flex;
      justify-content: center;

      svg {
        margin-left: .5em;
        * {
          stroke: inherit;
        }
      }
    }
  }
}

.LogoColors-download >div {
  align-items: center;
  display: flex;
}