.AssetDownload {
  display: grid;
  grid-gap: 1em;
}
.AssetDownload-close {
  background-color: transparent;
  border: 0;
  position: absolute;
  top: 0;
  right: 0;
  padding: 5px;
}
$assetDownloadPadding: 1.5rem;
.AssetDownload-preview {
  padding: $assetDownloadPadding;
  position: relative;
}
.AssetDownload-preview-image {
  svg {
    display: block;
    width: 100%;
    height: 100%;
  }
}
.AssetDownload-controls-sizes {

}
.AssetDownload-preview-width,
.AssetDownload-preview-height {
  align-items: center;
  display: flex;
  position: absolute;
  justify-content: center;
  height: $assetDownloadPadding;
  width: calc(100% - #{$assetDownloadPadding*2});

  &:before {
    border-top: 1px solid #aaa;
    content: '';
    position: absolute;
    width: 100%;
  }
  >div {
    background-color: white;
    padding: 0 10px;
    position: relative;
    line-height: 1;
  }
}
.AssetDownload-preview-width {
  bottom: 0;
  left: $assetDownloadPadding;
}

.AssetDownload-preview-height {
  transform: rotate(90deg);
  transform-origin: top left;
  left: 100%;
  top: $assetDownloadPadding;
}

.AssetDownload-controls {
  align-items: start;
  display: grid;
  grid-column-gap: 1em;
  padding: 0 $assetDownloadPadding;
  grid-template-columns: auto 100px;
  grid-template-rows: auto auto;
  grid-template-areas:
    "meta format"
  ;
}
.AssetDownload-controls-meta {
  grid-area: meta;
  margin: 0;
  dt {
    font-size: 80%;
    line-height: 1;
    opacity: .6;
  }
  dd {
    margin: 0;
  }
  dd+dt {
    margin-top: 1em;
  }
}
.AssetDownload-controls-format {
  grid-area: format;
}
.AssetDownload-controls-control--radio {
  display: grid;
  //grid-template-columns: repeat(3,1fr);
  grid-gap: 12px;
}
.AssetDownload-controls-control--radio-radiobutton {
  align-items: center;
  border: 1px solid;
  cursor: pointer;
  display: flex;
  justify-content: center;
  padding: 5px;
  input {
    display: none;
  }
  &--checked {
    border-color: var(--color);
    background-color: var(--color);
    color: white;
  }
}
.AssetDownload-controls-sizes-size {
  align-items: center;
  display: grid;
  grid-template-columns: 20px auto 1fr;

  input {
    appearance: none;
    background-color: transparent;
    border: unset;
    border-radius: 0;
    border-bottom: 1px solid;
    color: inherit;
    font-size: 100%;
    font: inherit;
    padding: 0;
    line-height: 1;
    &:focus {
      outline: 0;
    }
  }
}

.AssetDownload-link {
  align-items: center;
  border: 1px solid;
  color: inherit;
  display: flex;
  justify-content: center;
  padding: 10px;
  text-decoration: none;
  transition: opacity .3s;
  stroke: var(--color);

  svg {
    margin-left: .5em;
  }
  svg,
  svg * {
    stroke: inherit;
  }

  &[disabled] {
    pointer-events: none;
    opacity: .5;
  }
}