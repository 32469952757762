@import "~stylevariables/breakpoints";
@import "~stylevariables/type";

@font-face {
  font-family: 'domaine';
  src: url(fonts/DomaineTextWeb-Regular.woff);
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'domaine';
  src: url(fonts/DomaineTextWeb-RegularItalic.woff);
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'domaine';
  src: url(fonts/DomaineTextWeb-Bold.woff);
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'domaine';
  src: url(fonts/DomaineTextWeb-BoldItalic.woff);
  font-style: italic;
  font-weight: 700;
}

$family-label: $family-industry;
$family-sublabel: $family-aktiv;
$family-heading: $family-aktiv;
$family-body: $family-domaine;
$family-action: $family-industry;

.ff-aktiv {
  font-family: $family-aktiv;
}
.ff-industry {
  font-family: $family-industry;
}
.ff-domaine {
  font-family: $family-domaine;
}

.font-label {
  font-family: $family-label;
  text-transform: uppercase;
  line-height: 1;
  font-weight: 700;
  letter-spacing: 1.2px;
  @include fontSizes($labelSizes);
}

.font-sublabel {
  font-family: $family-sublabel;
  text-transform: uppercase;
  line-height: 1;
  font-weight: 700;
  @include fontSizes($sublabelSizes);
}
.font-value {
  font-family: $family-aktiv;
  text-transform: uppercase;
  line-height: 1.33;
  @include fontSizes($labelSizes);
}

.font-action {
  font-family: $family-action;
  text-transform: uppercase;
  @include fontSizes($labelSizes);
}

.font-hero-heading {
  font-family: $family-heading;
  text-transform: uppercase;
  line-height: 1.1;
  letter-spacing: 1.3px;
  font-weight: 700;
  @include fontSizes($heroSizes);
}

.font-heading {
  font-family: $family-heading;
  font-weight: 700;
  text-transform: uppercase;
  line-height: 1.3;

  @include fontSizes($headingSizes);
}

.font-subheading {
  font-family: $family-heading;
  font-weight: 700;
  line-height: 1.7;
  @include fontSizes($subheadingSizes);
}

.font-intro {
  font-family: $family-heading;
  font-weight: 500;
  line-height: 1.7;
  @include fontSizes($subheadingSizes);
}

.font-body {
  font-family: $family-body;
  font-size: 12px;
  line-height: 1.667;
  font-weight: normal;
  @include fontSizes($bodySizes);
}

.font-credit {
  font-style: italic;
  font-family: $family-body;
}

.font-specimin {
  @include fontSizes($speciminSizes);
}

.uppercase {
  text-transform: uppercase;
}