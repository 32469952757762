@import '~stylevariables/breakpoints';

.Assets {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 1.5rem;

  @include breakpoint(620px) {
    grid-template-columns: repeat(3, 1fr);
  }

  @include breakpoint(850px) {
    grid-template-columns: repeat(4, 1fr);
  }
}

.Assets-item {
  text-align: center;
}