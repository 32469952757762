@import '~stylevariables/breakpoints';
@import "~stylevariables/spacing";
$heroPad: (
  'base': 3rem,
  //'tablet': 6vw
);
.Hero {
  align-items: center;
  //display: grid;
  //grid-template-rows: 1fr auto;
  //height: 70vh;
  display: flex;
  justify-content: center;
  position: relative;

  @include breaks('padding', $heroPad);

  .LogoMorph {
    grid-row: 1;
    max-width: 500px;
    margin-left: auto;
    margin-right: auto;
    svg {
      height: 100%;
      width: auto;
    }
  }
}

.Home {
  display: grid;
  grid-template-rows: auto 1fr;

  .Nav {
    height: 100%;
  }
  .Nav-item {
    height: 100%;
  }

  @include breakpoint(500px) {
    min-height: calc(100vh - #{$navHeight});

    .Nav {
      //grid-template-columns: 1fr 1fr;
    }
    .Nav-item {
      //padding-bottom: 100%;
    }
  }
  @include breakpoint(800px) {
    .Hero {
      min-height: calc(50vh - #{$navHeight});
    }
    .Nav {
      grid-template-columns: repeat(3,1fr);
    }
    .Nav-item {
      //padding-bottom: 100%;
      height: 100%;
    }
  }

  @include breakpoint(1440px) {

  //   grid-template-columns: 1fr 1fr;
  //   .Hero {
  //     height: calc(100vh - #{$navHeight});
  //     position: sticky;
  //     top: $navHeight;
  //   }
  //   .Nav {
  //     width: 100%;
  //     grid-template-columns: 1fr;
  //     grid-template-rows: 1fr 1fr 1fr;
  //     height: calc(100vh - #{$navHeight});
  //   }
  }
}

.Hero-heading {
  text-align: center;
  //padding: 2em;
  h1 {
    margin-top: 0;
  }
}

.LogoMorph {
  height: 200px;
  svg {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
  }
}