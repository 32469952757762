@import "~stylevariables/colors";
@import "~stylevariables/breakpoints";

.Logos {
  display: grid;
  grid-gap: 6rem;
}

.Logos-section-heading {
  margin-bottom: 1em;
}
.Logos-section-grid {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  //grid-auto-rows: 35vmin;
  grid-gap: 2em;
}

.Logo {
  display: flex;
  flex-direction: column;
}
.Logo-title {
  text-align: center;
}

.Logo,
.LogoColors-download {
  cursor: pointer;
  position: relative;

  .hover-mark {
    align-items: center;
    display: none;
    position: absolute;
    top: 5px;
    right: 5px;
    z-index: 1;

  }
  svg {
    margin-left: .25em;
    * { stroke: inherit; }
  }
  &:hover .hover-mark {
    display: flex;
  }
}

.Logo a {
  text-decoration: none;
  color: inherit;
}

.Logo--full {
  grid-column: span 6;

  .Logo-image svg {
    width: 50%;
    height: 50%;
  }
}

.Logo--half {
  grid-column: span 3;

  .Logo-image svg {
    width: 75%;
    height: 75%;
  }
}

.Logo--third {
  grid-column: span 2;

  .Logo-image {
    padding: 2em;
  }

  .Logo-image svg {
    width: 100%;
    height: 100%;
  }
}

.Logo-image {
  align-items: center;
  display: flex;
  justify-content: center;
  height: 100%;
  background-color: $lightgray;

  // img {
  //   width: 60%;
  //   height: 60%;
  // }
}

.Logo-title {
  margin-top: .5em;
}

.LogosColors {
  margin-top: 6em;
}
.LogosColors-row {
  display: grid;
  grid-gap: .25em;

  &+.LogosColors-row {
    margin-top: .25em;
  }
  @include breakpoint($tablet) {
    grid-gap: 1em;

    &+.LogosColors-row {
      margin-top: 1em;
    }
  }
  svg {
    display: block;
  }
}